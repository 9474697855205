import * as React from "react";
import sjcl from "sjcl";
import "./Home.css";
export default class Home extends React.Component {
  private key = React.createRef<HTMLInputElement>();
  private salt = React.createRef<HTMLInputElement>();
  private hash = React.createRef<HTMLInputElement>();
  buttonEvent() {
    if (this.key.current && this.salt.current && this.hash.current) {
      let hash: string;
      this.salt.current.value = this.getSeed(this.key.current.value);

      this.hash.current.value = this.generateHash(
        this.salt.current.value,
        this.key.current.value
      );
    }
  }
  /**
   * Generates a hash
   * @param salt A salt to generate a hash (string)
   * @param key A private key to generate a hash (string) [secret word]
   * @returns
   */
  generateHash(salt: string, key: string) {
    const myBitArray = sjcl.hash.sha256.hash(salt + key);
    const myHash = sjcl.codec.hex.fromBits(myBitArray);
    return myHash;
  }
  /**
   * Gets a seed (salt) to generate a hash
   * @param key A private key to generate a hash (string) [secret word]
   * @returns
   */
  getSeed(key: string): string {
    const myBitArray = sjcl.hash.sha256.hash(String(Date.now() + key));
    const mySeed = sjcl.codec.hex.fromBits(myBitArray);
    return mySeed;
  }
  render() {
    return (
      <React.Fragment>
        <div className="form-group gen_hash">
          <label>Secret word</label>
          <input className="form-control" type="email" ref={this.key} />
          <label>Salt</label>
          <input
            className="form-control"
            type="text"
            ref={this.salt}
            readOnly
          />
          <label>Hash</label>
          <input
            className="form-control"
            type="text"
            ref={this.hash}
            readOnly
          />
          <button
            className="btn btn-primary"
            onClick={() => this.buttonEvent()}
          >
            Generate
          </button>
        </div>
      </React.Fragment>
    );
  }
}
