import * as React from "react";
import sjcl from "sjcl";
import "./Home.css";
export default class Recover extends React.Component {
  private privateKey = React.createRef<HTMLInputElement>();
  private recoverSalt = React.createRef<HTMLInputElement>();
  private recoverHash = React.createRef<HTMLInputElement>();

  /**
   * Generates a hash
   * @param salt A salt to generate a hash (string)
   * @param key A private key to generate a hash (string) [secret word]
   * @returns
   */
  generateHash(salt: string, key: string) {
    const myBitArray = sjcl.hash.sha256.hash(salt + key);
    const myHash = sjcl.codec.hex.fromBits(myBitArray);
    return myHash;
  }

  /**
   * Recover a hash with a hashed secret key
   */
  recover() {
    if (
      this.privateKey.current &&
      this.recoverSalt.current &&
      this.recoverHash.current
    ) {
      this.recoverHash.current.value = this.generateHash(
        this.recoverSalt.current.value,
        this.privateKey.current.value
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="form-group gen_hash">
          <label>Key</label>
          <input className="form-control" type="text" ref={this.privateKey} />
          <label>Salt</label>
          <input className="form-control" type="text" ref={this.recoverSalt} />
          <label>Hash</label>
          <input
            className="form-control"
            type="text"
            ref={this.recoverHash}
            readOnly
          />
          <button className="btn btn-primary" onClick={() => this.recover()}>
            Recover
          </button>
        </div>
      </React.Fragment>
    );
  }
}
